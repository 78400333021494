<template>
    <div class="surface-0 overflow-hidden">
        <div class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
            <router-link to="/" class="flex align-items-center">
                <img :src="'layout/images/logo-' + logoColor + '.svg'" alt="Logo" height="50" class="mr-0 lg:mr-2"><span class="text-900 font-medium text-2xl line-height-3 mr-8">OrchestrateID</span>
            </router-link>
            <a class="cursor-pointer block lg:hidden text-700 p-ripple" v-ripple
                v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top:92%">
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                    <li>
                        <a @click="smoothScroll('#hero')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span>Home</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#features')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span>Features</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#subscribe')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span>Subscribe</span>
                        </a>
                    </li>
                </ul>
                <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                    <Button
                    label="Join Waitlist"
                    class="p-button-rounded border-none ml-5 font-light text-white line-height-2 bg-blue-500"
                    @click="smoothScroll('#subscribe')"
                    ></Button>                
                </div>
            </div>
        </div>

        <div class="grid py-4 px-4 lg:px-8 relative" id="hero">
            <div class="mx-4 md:mx-8 mt-0 md:mt-4">
                <h1 class="text-6xl font-bold text-gray-900 line-height-2"><span class="font-light block">OrchestrateID</span></h1>
                <p class="font-normal text-2xl line-height-3 md:mt-3 text-gray-700">Orchestrate your customer Identity</p>
                <Button label="Get Started" class="p-button-rounded text-xl border-none mt-5 bg-blue-500 font-normal text-white line-height-3 px-3" @click="smoothScroll('#subscribe')"></Button>
            </div>
            <img src="/images/screen-orchestrator.png" class="bottom-0" alt="hero screen" style="right:10%;">
        </div>
        
        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="features">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-8 mb-4">
                    <h2 class="text-900 font-normal mb-2">Main Features</h2>
                    <span class="text-600 text-2xl">Fit into your existing Identification Processes...</span>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(145, 210, 204, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-bluegray-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-id-card text-2xl text-bluegray-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Identify your customers</h5>
                            <span class="text-600">Scan and read all types of ID Documents.</span>
                        </div>
                    </div>
                </div>

                
                <div class="col-12 md:col-12 lg:col-4 p-0 lg-4 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-purple-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-eye text-2xl text-purple-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Privacy Focus</h5>
                            <span class="text-600">Reduce risk through proven audits and compliance to EU Regulations.</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-yellow-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Multi-user, Multi-tenant</h5>
                            <span class="text-600">Fit your working style and your organisation. </span>
                        </div>
                    </div>
                </div>
    
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(145, 226, 237, 0.2), rgba(172, 180, 223, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(246, 158, 188, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-indigo-200" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-bolt text-2xl text-indigo-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Fast</h5>
                            <span class="text-600">Get the data into your systems as soon as it is available with parallel data streams.</span>
                        </div>
                    </div>
                </div>
        
                
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(145, 226, 237, 0.2),rgba(160, 210, 250, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-orange-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-cloud text-2xl text-orange-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Modern Solution</h5>
                            <span class="text-600">You can host it, or use managed devices and cloud infrastructure.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(160, 210, 250, 0.2)), linear-gradient(180deg, rgba(187, 199, 205, 0.2), rgba(145, 210, 204, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-teal-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-shopping-cart text-2xl text-teal-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Eficient Pricing</h5>
                            <span class="text-600">Our use case is based on saving you money.</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
            
        <div class="py-4 px-4 lg:px-8 mx-0 my-6 lg:mx-8" id="subscribe">
            <div class="text-center">
                <div class="col-12 mt-8 mb-8 p-2 md:p-8" style="border-radius:20px; background:linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%);">
                    <div class="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">    
                        <h2 class="line-height-1 text-900 text-4xl font-normal">Be the first to be notified about the public launch</h2>
                        <span class="text-gray-600 text-2xl">Stay updated with our latest news and offers. Join our mailing list today!</span>
                        <div class="flex align-items-center mt-4">
                            <InputText 
                            type="email" 
                            v-model="email" 
                            placeholder="Enter your email" 
                            class="p-inputtext-sm" 
                            required 
                            />
                            <Button 
                            label="Subscribe" 
                            class="p-button-sm ml-2" 
                            @click="subscribeEmail" 
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="py-4 px-4 mx-0 mt-8 lg:mx-8" id="contact">
            <div class="grid justify-content-between">
                <div class="col-12 md:col-2" style="margin-top:-1.5rem;">
                    <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                        <img :src="'layout/images/logo-' + logoColor + '.svg'" alt="footer sections" width="50" height="50" class="mr-2">
                        <h4 class="font-medium text-3xl text-900">OrchestrateID</h4>
                    </div>
                </div>

                <!-- <div class="col-12 md:col-7">
                    <div class="grid text-center md:text-left">
                        <div class="col-12 md:col-3">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Company</h4>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">About Us</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">News</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Investor Relations</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Careers</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Media Kit</a>
                        </div>
                        
                        <div class="col-12 md:col-3 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Resources</h4>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Get Started</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Learn</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Case Studies</a>
                        </div>
        
                        <div class="col-12 md:col-3 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Community</h4>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Discord</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Events<img src="layout/images/new-badge.svg" class="ml-2"/></a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">FAQ</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Blog</a>
                        </div>
        
                        <div class="col-12 md:col-3 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Legal</h4>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Brand Policy</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Privacy Policy</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Terms of Service</a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '', // Initialize email property
        };
    },
    methods: {
        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },
        async subscribeEmail() {
            if (this.email && this.validateEmail(this.email)) {
                // Form data to send
                const formData = new FormData();
                formData.append('email', this.email);

                try {
                const response = await fetch('https://orchestrateid.com/subscribe.php', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    alert('Thank you for subscribing!');
                    this.email = ''; // Clear input after success
                } else {
                    alert('There was an error. Please try again later.');
                }
                } catch (error) {
                alert('An error occurred. Please try again.');
                }
            } else {
                alert('Please enter a valid email address.');
            }
        },
        validateEmail(email) {
        // Basic email validation regex
        const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return re.test(String(email).toLowerCase());
        },
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style>
#hero{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EEEFAF 0%, #C3E3FA 100%);
    height:700px;
    overflow:hidden;
}

@media screen and (min-width: 768px) {
    #hero{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 530px;
    }
}

@media screen and (min-width: 1300px){
    #hero > img {
        position: absolute;
    }

    #hero > div > p { 
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px){
    #hero {
        height: 600px;
    }

    #hero > img {
        position:static;
        transform: scale(1);
        margin-left: auto;
    }

    #hero > div {
        width: 100%;
    }

    #hero > div > p {
        width: 100%;
        max-width: 100%;
    }
}
</style>